.dialog .MuiPaper-root {
    width: 400px;    
}

.dialog__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
.dialog__body {
  display: flex;
  flex-direction: column;
}
.dialog__label{
  font-weight: bold;
}
.private-note__checkbox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.private-note__textarea,
.private-note__note-reason {
  display: flex;
  gap: 5px;
}
.private-note__note-reason {
  .Mui-error{
    margin-left: 0!important;
  }
}
.private-note__textarea textarea {
  resize: vertical !important;
  height: 300px;
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
}

